import type { ComponentStyleConfig } from "@chakra-ui/theme";

const commonMenuListProps = {
	boxShadow: "drop",
	borderWidth: 0,
	backgroundColor: "brand.lighter",
};

const menuListStyle = {
	display: "flex",
	flexDir: "column",
	paddingTop: 1,
	paddingBottom: 1,
	...commonMenuListProps,

	div: {
		fontWeight: "medium",
		fontSize: "xs",
		padding: "0.5rem 1rem",
		whiteSpace: "pre",
	},

	"button, a": {
		fontWeight: "medium",
		fontSize: "xs",
		textDecor: "none",
		background: "none",
		lineHeight: 1.5,
		height: "unset",
		padding: "0.5rem 1rem",
		width: "100%",
		justifyContent: "start",
		borderRadius: "none",
		textAlign: "left",

		_hover: {
			backgroundColor: "brand.highlight",
			color: "#ffffff",
		},
	},
};

const ContextMenu: ComponentStyleConfig = {
	baseStyle: {
		menuList: {
			...menuListStyle,
		},
		deleteButton: {
			color: "#FA7A7B",
		},
		divider: {
			borderW: "1px",
			color: "brand.blue",
			opacity: "0.8",
		},
		bulkContextMenuButton: {
			height: "2.5rem",
			paddingY: 3,
			paddingLeft: 2,
			paddingRight: 2,
			fontWeight: "medium",
			fontSize: "sm",
			backgroundColor: "brand.lighter",
			display: "flex",
			gap: 4,
			h: "full",

			"> span:first-of-type": {
				overflow: "hidden",
				textOverflow: "ellipsis",
			},
		},
		bulkContextMenuList: {
			fontSize: "xs",
			...commonMenuListProps,

			button: {
				height: "2.5rem",
				fontWeight: "bold",
				backgroundColor: "brand.lighter",
				_hover: {
					color: "#ffffff",
					backgroundColor: "brand.highlight",
				},
			},
		},
		chevronUp: {
			transform: "rotate(-0.5turn)",
			color: "brand.highlight",
		},
		tooltip: {
			background: "brand.medium",
		},
	},
};

export default ContextMenu;
