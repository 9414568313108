import { headerTypographyMixin } from "../components/Header/Header.theme";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const commonButtonVariantStyles = {
	borderRadius: "md",
	px: 4,
	py: 3,
	display: "inline-flex",
	gap: 2,
	alignItems: "center",
	justifyContent: "center",
	fontWeight: "semibold",
	textDecoration: "none",
};

const Link: ComponentStyleConfig = {
	baseStyle: {
		textDecoration: "underline",
		outline: "unset",

		_hover: {
			textDecoration: "none",
		},
	},
	variants: {
		text: {
			...commonButtonVariantStyles,
			px: 0,
			textDecoration: "none",
			_hover: {
				color: "brand.highlight",
			},
		},
		solid: {
			...commonButtonVariantStyles,
			bg: "brand.highlight",
			color: "white",

			_hover: {
				color: "white",
				bg: "brand.highlight.hover",
			},
		},
		outline: {
			...commonButtonVariantStyles,
			bg: "transparent",
			border: "none",
			boxShadow: "inset 0 0 0 2px currentColor",

			_hover: {
				bg: "brand.lighter",
				color: "brand.blue.darker",
			},
		},
		headerDropdown: {
			...headerTypographyMixin,
			pl: 8,
			display: "block",
			textDecoration: "none",
			textAlign: "left",
			_hover: {
				color: "brand.highlight",
			},
		},
	},
};

export default Link;
