import { cssVar, visuallyHiddenStyle } from "@chakra-ui/react";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const columnColor = cssVar("vcs-column-color");
const hoverColor = cssVar("vcs-column-color-hover");
const bgColor = cssVar("vcs-column-color-bg");
const fontColor = cssVar("vcs-column-color-font");
const { reference: colorHighlight } = cssVar("chakra-colors-brand-highlight");
const { reference: colorHighlightDisabled } = cssVar(
	"chakra-colors-brand-highlight-disabled",
);
const { reference: colorHighlightFaint } = cssVar(
	"chakra-colors-brand-highlight-faint",
);
const { reference: colorBlue } = cssVar("chakra-colors-brand-blue");
const { reference: colorBlueDark } = cssVar("chakra-colors-brand-blue-dark");

const TabsOrColumns: ComponentStyleConfig = {
	parts: ["tabs", "tab", "columns", "column", "columnTitle"],
	baseStyle: ({ isTabbed, desktopBreakpoint, showDivider }) => {
		return {
			tabs: {
				justifyContent: "center",
				paddingTop: 16,
				...(isTabbed ? {} : visuallyHiddenStyle),
				px: 4,
			},
			tab: {
				color: "brand.blue",
				fontWeight: "bold",
				textShadow: "none",
				boxShadow: "drop",
			},
			columns: {
				marginTop: { base: 0, [desktopBreakpoint]: 8 },
				paddingTop: { base: 0, [desktopBreakpoint]: 8 },

				...(isTabbed
					? {}
					: {
							position: "relative",
							display: "flex",

							_last: {
								_after: {
									content: `""`,
									display: showDivider ? "block" : "none",
									position: "absolute",
									width: 0,
									height: "100%",
									left: "50%",
									transform: "translateX(-50%)",
									borderLeft: "3px solid",
									borderColor: "brand.blue.translucent",
								},
							},
						}),
			},
			columnTitle: {
				textAlign: "center",
				border: "1.5px solid",
				borderRadius: "2xl",
				borderColor: columnColor.reference,
				bg: bgColor.reference,
				...(isTabbed ? visuallyHiddenStyle : {}),
				paddingX: 4,
				color: fontColor.reference,
				boxShadow: "drop",
			},

			column: {
				paddingTop: { base: 8, [desktopBreakpoint]: 16 },

				[columnColor.variable]: colorHighlight,
				[bgColor.variable]: colorHighlightFaint,
				[hoverColor.variable]: colorHighlightDisabled,
				[fontColor.variable]: colorBlue,
				_last: {
					[columnColor.variable]: colorBlue,
					[bgColor.variable]: colorBlue,
					[hoverColor.variable]: colorBlueDark,
					[fontColor.variable]: "white",
				},

				...(isTabbed
					? {}
					: {
							flex: 1,

							"&[hidden]": {
								display: "block !important",
							},
						}),
			},
		};
	},
};

export default TabsOrColumns;
