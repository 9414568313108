import type { ComponentStyleConfig } from "@chakra-ui/theme";

const FormSelect: ComponentStyleConfig = {
	baseStyle: ({ isFilled }) => ({
		color: isFilled ? "brand.blue" : "ui.placeholder",
	}),
	variants: {
		outline: {
			select: {
				border: "2px solid",
				borderColor: "brand.light",
			},
		},
		horizontal: {
			display: "flex",
			alignItems: "center",
			flexDirection: "row",
			flexWrap: "wrap",
			rowGap: 2,

			label: {
				flex: "initial",
				margin: 0,
				paddingRight: 6,
			},
			div: {
				flex: 1,
				select: {
					minWidth: 64,
				},
			},
		},
		light: {
			label: {
				color: "white",
			},

			select: {
				bg: "var(--chakra-colors-gray-100)",
			},
		},
	},
};

export default FormSelect;
