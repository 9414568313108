import { ComponentStyleConfig } from "@chakra-ui/react";
import { baseOffVariant } from "./themeUtils";
import { figmaHelper } from "./typography";

const PinInput: ComponentStyleConfig = {
	variants: {
		filled: baseOffVariant("PinInput", "filled", {
			"--chakra-colors-chakra-placeholder-color": "white",
			background: "brand.blue",
			color: "white",
			fontWeight: "bold",
			...figmaHelper(36, 49),

			":placeholder-shown:not(:focus-visible)": {
				background: "brand.blue.disabled",
			},

			_hover: {
				background: "brand.blue.dark",
			},

			_focusVisible: {
				background: "brand.blue.dark",
				borderColor: "brand.blue.translucent",
			},

			_placeholder: {
				color: "white",
			},
		}),
	},
	defaultProps: {
		variant: "filled",
	},
};

export default PinInput;
