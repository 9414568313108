import type { ComponentStyleConfig } from "@chakra-ui/theme";

const ContactForm: ComponentStyleConfig = {
	baseStyle: {
		formWrapper: {
			display: "flex",
			flexDirection: "column",
			width: "100%",
			height: "100%",
			paddingTop: { base: 5, md: 10 },
			paddingBottom: { base: 7, md: 14 },
			paddingX: { base: 4, lg: 10 },
			margin: "0 auto",
		},
		content: {
			display: "flex",
			flexDirection: "column",
			height: "100%",
		},
		formTitleWrapper: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			paddingBottom: 8,
			color: "brand.blue",
		},
		formTitle: {
			display: "flex",
			gap: 2,

			"& svg": {
				alignSelf: "center",
				w: 6,
				h: 6,
			},
		},
		formControl: {
			paddingBottom: 3,

			"& input, textarea": {
				backgroundColor: "brand.lighter",
				color: "brand.blue",
			},

			"& textarea": {
				height: 36,
				color: "brand.blue",
			},

			"& select": {
				backgroundColor: "brand.lighter",
				border: "1px solid transparent",
				boxShadow: "none",
			},

			"& select:hover": {
				border: "1px solid transparent",
				bg: "gray.200",
			},
		},
		userAvailableLabel: {
			paddingY: 3,
			color: "brand.blue",
			fontWeight: "medium",
		},
		timeAndDate: {
			display: "flex",
			gap: 4,

			"& > *": {
				flex: "auto",
			},
		},
		buttonWrapper: {
			marginTop: "auto",
			paddingTop: { base: 7, md: 24 },

			svg: {
				width: "auto",
				height: 6,
			},
		},
		formSent: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			flexDirection: "column",
			textAlign: "center",
			height: "100%",
		},
		formHelperText: {
			textAlign: "right",
			color: "brand.blue",
		},
	},
};

export default ContactForm;
