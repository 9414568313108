import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Carousel: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			position: "relative",
			"& .swiper-slide": {
				textAlign: "center",
				width: {
					base: "280px",
					md: "360px",
				},
				height: "auto",
				backgroundColor: "brand.lighter",
				boxShadow: "carousel",
				opacity: 0.4,
				transition: "opacity 0.5s",
			},
			"& .swiper-slide-active": {
				opacity: 1,
			},
			"& .swiper-slide-next, .swiper-slide-prev": {
				opacity: {
					base: 0.4,
					lg: 1,
				},
			},
		},
		pagination: {
			position: "static",
			display: "flex",
			gap: 0,
			overflowX: "auto",

			".swiper-pagination-bullet": {
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				w: 12,
				h: 12,
				bg: "transparent",
				color: "brand.blue",
				fontWeight: "medium",
				flexShrink: 0,
				cursor: "pointer",
			},

			".swiper-pagination-bullet.swiper-pagination-bullet-active": {
				bg: "brand.blue",
				color: "white",
			},
		},
		control: {
			bg: "white",
			width: "fit-content",
			boxShadow: "drop",
			gap: 0,
			p: 4,
			mx: "auto",
			mb: 4,
			overflowX: "auto",
			maxW: "calc(100% - 2rem)",
			borderRadius: "6px",

			".swiper-button-disabled": {
				color: "var(--chakra-colors-brand-light) !important",
			},
		},
		button: {
			position: "static",
			marginTop: 0,
			width: "3rem",
			height: "3rem",
			borderRadius: "6px",
			border: "none",
			boxShadow: "none",
			flexShrink: 0,
			color: "brand.blue",
			bg: "brand.lighter",

			_hover: {
				color: "white",
				bg: "brand.blue",
			},
		},
	},
};

export default Carousel;
