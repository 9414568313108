export const funkThemeColors = {
	red: {
		// this override ensures we use our red for all erroring UI
		500: "#DC2726",
	},
	gray: {
		medium: "#D9D9D9",
		dark: "#333333",
	},
	brand: {
		light: "#D0DEEB",
		lighter: "#F4F6FA",
		"lighter.translucent": "rgba(244, 246, 250, 0.5)",
		medium: "#6F89A4",
		blue: "#005092",
		"blue.disabled": "rgba(25, 100, 197, 0.5)",
		"blue.translucent": "rgba(25, 100, 197, 0.12)",
		"blue.dark": "#003366",
		"blue.darker": "#021B47",
		highlight: "#51C519",
		"highlight.hover": "#40ad30",
		"highlight.disabled": "rgba(81, 197, 25, 0.5);",
		"highlight.faint": "rgba(81, 197, 25, 0.25);",
	},
	status: {
		purple: "#C1BEDF",
		blue: "#AAC8E9",
		green: "#52C51A80",
		red: "#FAAFB0",
		yellow: "#FFCD2980",
		gray: "#E0E0E0",
		orange: "#F7C5A6",
	},
	shadows: {
		pop: "rgba(8, 26, 45, 0.16)",
		drop: "rgba(0, 0, 0, 0.1)",
		text: "rgba(0, 0, 0, 0.25)",
	},
	ui: {
		error: "#DC2726",
		focus: "#51C519",
		loading: "#40ad30",
		skeleton: "#9eafc2",
		inactive: "#999999",
		placeholder: "#021B4799",
	},
} as const;

// 🔬 TBD
